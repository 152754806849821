





















import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    participantId: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props, { root }) {
    const state = reactive({
      success: false,
      error: false as boolean | number,
      participant: {},
      loading: true,
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`participant/${props.participantId}`)
        .then(({ data: { participant } }) => {
          state.participant = participant;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    return { state };
  },
});
